<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> <div>
        <router-link class="crumbs_item" tag="a" to="/admin/channelRecord">结算记录</router-link>
        /
        订单明细</div> </template>
    </HeaderBox>
    <div class="selects">
      <a-button @click="onAdd">新增</a-button>
      <a-button @click="onExportOrder">导出</a-button>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index)=>item.orderProductId"
        :scroll="{ x: 1800 }"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="channelBilling" slot-scope="text">
          <a-tag :color="text == 1 ? 'green' : 'red'">
            {{text == 1 ? '已结算' : '未结算'}}
          </a-tag>
        </template>
        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="deleteData(row)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 未结算订单列表 -->
    <a-modal
      title="未结算订单列表"
      :visible="orderListVisible"
      @cancel="orderListVisible = false"
      :confirm-loading="loading"
      okText="确认"
      width="1000px"
      @ok="onNext()">
      <a-table
        class="table-template"
        :rowKey="(item,index)=>item.orderProductId"
        :scroll="{ x: 1800 }"
        :columns="columns"
        :data-source="orderList"
        @change="onOrderPage"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onChangeSingle,
          columnTitle: '选择',
          getCheckboxProps: (record) => ({
            props: {
              disabled: false,
            },
          }),
        }"
        :pagination="{
          total:orderTotal,
          current:orderPageNumber,  
          defaultPageSize:orderPageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
          <template slot="footer">
          <a-checkbox
            @change="onChangeAll"
            :checked="isChecked"
            class="allSellect"
          >
            全选
          </a-checkbox>
        </template>
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (orderPageNumber - 1) * orderPageSize + i + 1 }}
          </div>
        </template>
        <template slot="channelBilling" slot-scope="text">
          <a-tag :color="text == 1 ? 'green' : 'red'">
            {{text == 1 ? '已结算' : '未结算'}}
          </a-tag>
        </template>
        <template slot="operation">
          <div class="btn_router_link">
            -
          </div>
        </template>
      </a-table>
    </a-modal>
    <!-- 确认结算订单 -->
    <a-modal
      title="确认结算订单"
      :visible="orderOkVisible"
      @cancel="orderOkVisible = false"
      :confirm-loading="loading"
      @ok="onSettOption"
    >
      <div class="content">
        <div class="list">
          <span class="name must">渠道方：</span>
          <a-input
            class="input"
            :disabled="true"
            v-model="tChannelBillingDTO.channelName"
            placeholder="请输入渠道方"
          />
        </div>
        <div class="list">
          <span class="name must">结算说明：</span>
          <a-input
            v-model="tChannelBillingDTO.billingInstructions"
            class="input"
            placeholder="请输入结算说明"
          />
        </div>
        <div class="list">
          <span class="name must">结算金额：</span>
          <a-input
            v-model="tChannelBillingDTO.billingAmount"
            class="input"
            placeholder="请输入结算金额"
          />
        </div>
        <div class="list">
          <span class="name must">经手人：</span>
          <a-input
            v-model="tChannelBillingDTO.handler"
            class="input"
            placeholder="请输入经手人"
          />
        </div>
        <div class="list">
          <span class="name must">结算时间：</span>
          <a-date-picker show-time class="input" valueFormat="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" v-model="tChannelBillingDTO.billingTime" placeholder="请选择结算时间" />
        </div>
        <div class="list">
          <span class="name">结算周期：</span>
          <a-date-picker 
            v-model="year" 
            :open='pickerShow'  
            @openChange="openChange" 
            @panelChange="panelChange" 
            @change="clearChange"
            placeholder="年度" 
            format="YYYY" 
            style="width:40%"
            class="picker" 
            mode="year" />
          <a-select
            placeholder="季度"
            v-model="quarter"
            style="width:40%"
            allowClear
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="1"> 第一季度 </a-select-option>
            <a-select-option :value="2"> 第二季度 </a-select-option>
            <a-select-option :value="3"> 第三季度 </a-select-option>
            <a-select-option :value="4"> 第四季度 </a-select-option>
          </a-select>
        </div>
        <div class="list">
          <span class="name">结算材料：</span>
          <a-upload
            :remove="removeImg"
            list-type="picture-card"
            :fileList="fileList"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <div v-if="fileList.length < 5">
              <a-icon :type="uploading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传凭证</div>
            </div>
          </a-upload>
        </div>
      </div>
    </a-modal>
    
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    fixed: "left",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "用户名称",
    align: "center",
    width: 100,
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "子订单编码",
    align: "center",
    dataIndex: "subCode",
  },
  {
    title: "商品名称",
    align: "center",
    ellipsis: "true",
    width: 260,
    dataIndex: "productName",
  },
  {
    title: "商品数量",
    align: "center",
    dataIndex: "productCount",
  },
  {
    title: "子订单实付(元)",
    align: "center",
    dataIndex: "payPrice",
  },
  {
    title: "订单实付(元)",
    align: "center",
    dataIndex: "orderPrice",
  },
  {
    title: "退款金额(元)",
    align: "center",
    dataIndex: "refundPrice",
  },
  {
    title: "支付时间",
    align: "center",
    dataIndex: "paymentTime",
  },
  {
    title: "结算状态",
    align: "center",
    dataIndex: "channelBilling",
    scopedSlots: { customRender: "channelBilling" },
  },
  {
    title: "渠道名称",
    align: "center",
    dataIndex: "channelName",
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      billingId: 0,
      channelName: '',
      orderListVisible:false,
      orderOkVisible:false,
      loading: false,
      uploading:false,
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      tableData: [],

      orderList: [],
      orderTotal: 0,
      orderPageNumber: 1,
      orderPageSize: 10,
      tChannelBillingDTO:{
        id:0,
        channelCode:'',//	渠道 id
        channelName:'', // 渠道名称
        billingInstructions:'', // 结算说明
        billingAmount:undefined, //结算金额
        handler:'',//经手人
        billingTime:'',//结算时间
        billingQuarter:'', //结算周期
        billingFile:"", // 结算材料
        billingItems:[]
      },
      fileList: [], //材料结合
      year: null,
      quarter:undefined,
      pickerShow:false,
      isChecked:false,
      selectItemAll:[],
      billingItems:[],
      selectedRowKeys: [],
    }
  },
  // 事件处理器
  methods: {
    moment,
    clearChange(e){
      this.year = null
    },
    panelChange(e){
      let time = moment(e).format("YYYY");
      this.year = time
      this.pickerShow = false
    },
    openChange(e){
      this.pickerShow = true
    },
    onPage(e){
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;    
    },
    onOrderPage(e){
      this.orderPageNumber = e.current;
      this.orderPageSize = e.pageSize;    
    },
    onAdd(){
      if(!this.orderTotal){
        return this.$message.warning("暂无可选未结算订单");
      }
      this.orderListVisible = true
    },
    // 获取订单明细列表
    getManageList() {
      this.loading = true
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/channel/channel/order/product/billing/list',
        params: {
          billingId: this.billingId
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data
          this.total = res.data.length
        }
      })
    },
    // 获取未结算渠道订单列表
    getUnsettledList() {
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/channel/channel/order/product/list',
        params: {
          channelName: this.tChannelBillingDTO.channelName,
          channelBilling: 0,
          pageNum: 1,
          pageSize: 1000,
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.orderList = res.data.records
          this.orderTotal = res.data.total
        }
      })
    },
    onNext(){
      if(!this.selectedRowKeys.length){
        return this.$message.warning("请勾选订单");
      }
      this.loading = true
      this.$ajax({
        method: 'POST',
        url: '/hxclass-management/channel/billing/audit',
        params: {
          id: this.billingId,
          billingItems: this.billingItems
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.orderListVisible = false
          this.isChecked = false
          this.selectedRowKeys = []
          this.billingItems = []
          this.getManageList();
          this.getUnsettledList()
        } else {
          this.$message.error(res.message);
        }
        this.loading = false
      })
    },
    // 单选
    onChangeSingle(selectedRowKeys,e){
      this.selectItemAll[this.pageNumber - 1] = e
      if(this.selectItemAll[this.pageNumber - 1].length == this.orderList.length){
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      this.funSelectedRowKeys()
    },
    //全选
    onChangeAll(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        this.selectItemAll[this.pageNumber - 1] = this.orderList
      } else {
        this.selectItemAll[this.pageNumber - 1] = []
        this.isChecked = false;
      }
      this.funSelectedRowKeys()
    },
    funSelectedRowKeys(){
      this.selectedRowKeys = []
      this.billingItems = []
      this.selectItemAll.forEach(element => {
        element.forEach(subItem => {
          this.selectedRowKeys.push(subItem.orderProductId)
          this.billingItems.push({
            orderProductId: subItem.orderProductId,
            productCount: subItem.productCount,
            productId: subItem.productId,
            productName: subItem.productName,
            channelBilling: subItem.channelBilling
          })
        });
      });
    },
    // 导出订单明细
    onExportOrder() {
      if(!this.total){
        return this.$message.warning("暂无订单数据，无法导出！");
      }
      window.open(
        this.$config.target +
        "/hxclass-management/channel/channel/order/product/billing/export?billingId=" +
        encodeURIComponent(this.billingId)
      );
    },
    // 删除已结算数据
    deleteData(e) {
      let _this = this;
      let obj = {
        billingItems: [
          {
            id: e.channelBillingItemId,
            orderProductId: e.orderProductId,
          }
        ]
      }
      this.$confirm({
        title: "确定删除该条数据吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/channel/billing/delete",
              method: "POST",
              params: obj
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.getManageList();
                _this.getUnsettledList()
              }
            });
        },
        onCancel() {},
      });
    },
    // 批量结算订单
    onSettOption(){
      if(!this.tChannelBillingDTO.billingInstructions){
        return this.$message.warning("请输入结算说明");
      } else if(!this.tChannelBillingDTO.billingAmount){
        return this.$message.warning("请输入结算金额");
      } else if(!this.tChannelBillingDTO.handler){
        return this.$message.warning("请输入经手人");
      } else if(!this.tChannelBillingDTO.billingTime){
        return this.$message.warning("请选择结算时间");
      } else if (this.year && !this.quarter || !this.year && this.quarter) {
        return this.$message.warning("请确认结算周期数据是否完整");
      }

      this.tChannelBillingDTO.billingItems = this.billingItems // 所选订单结集合
      var imgStr = (this.fileList.length ? this.fileList.map(item => item.url).join(',') :undefined)
      this.tChannelBillingDTO.billingFile = (imgStr ? imgStr.toString() : undefined)
      this.tChannelBillingDTO.billingQuarter = (this.year ? this.year + '-' + this.quarter : undefined)
      this.loading = true
      this.$ajax({
        method: 'POST',
        url: '/hxclass-management/channel/billing/audit',
        params: this.tChannelBillingDTO
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.orderOkVisible = false
          this.tChannelBillingDTO = {
            id: this.billingId,
            channelCode: this.$route.query.channelCode,//	渠道 id
            channelName: this.$route.query.channelName, // 渠道名称
            billingInstructions:'', // 结算说明
            billingAmount:undefined, //结算金额
            handler:'',//经手人
            billingTime:'',//结算时间
            billingFile:"", // 结算材料
            billingQuarter:"",
            billingItems:[]
          }
          this.isChecked = false
          this.year = null
          this.quarter = undefined
          this.fileList = []
          this.selectedRowKeys = []
          this.billingItems = []
          this.getManageList();
          this.getUnsettledList()
        } else {
          this.$message.error(res.message);
        }
        this.loading = false
      })
    },
    // 图片删除回调
    removeImg(e) {
      this.fileList.map((item, index) => {
        if (item.uid == e.uid) {
          this.fileList.splice(index, 1);
        }
      });
    },
    // 上传前格式校验
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    customRequest(info) {
      let _this = this;
      this.file = info.file;
      this.fileList.push(info.file);
      this.uploading = true;

      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(info.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          _this.fileList.forEach((item) => {
            if (item.uid == _this.file.uid) {
              item["url"] = url;
            }
          });
        } else {
          this.$message.error("上传失败");
        }
        this.uploading = false;
      });
    },
    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.billingId = this.$route.query.billingId
    this.tChannelBillingDTO.id = this.$route.query.billingId
    this.tChannelBillingDTO.channelName = this.$route.query.channelName
    this.tChannelBillingDTO.channelCode = this.$route.query.channelCode
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getManageList()
    this.getUnsettledList()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.selects {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-end;
  .input{
    width: 220px;
    margin-right: 20px;
    margin-bottom: 16px;
  }
  .ant-btn {
    margin-right: 24px;
  }
  .ant-btn:last-child {
    margin-right: 0;
  }
}
.content {
  margin: 10px auto;
  .add-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .list {
    display: flex;
    margin: 10px 0;
    padding-right: 30px;
    align-items: baseline;
    .name {
      display: block;
      flex-shrink: 0;
      width: 95px;
      margin-right: 5px;
      text-align: right;
      
    }
    .must{
      &::before{
        content: "*";
        color: red;
      }
    }
    .input {
      flex: 1;
    }
  }
  .tips{
    padding-left: 30px;
  }
}
</style>
